// React
import React, { useState } from "react";

// Thirt Part
import { Row, Col, Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import ReactLoading from "react-loading";

// Custom
import {
  CustomInput,
  CustomMap,
  InfoArea,
  Header,
  Footer,
  InfoComponent,
} from "./components/";
import "./index.css";

// Api
import { getData } from "./service/api";

const Main = () => {
  const [data, setData] = useState(null);
  const [zoom] = useState(1);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState();
  const [iconVisible, setIconVisible] = useState();

  const onhandleSubmit = (val) => {
    setLoading(true);
    getData(val.address, setData, setLoading, setIconVisible);
    setUrl(val.address);
  };

  return (
    <>
      <ToastContainer />
      {loading ? (
        <Container className="border border-secondary bg-light rounded">
          <div className="d-flex justify-content-center">
            <div className="spinner">
              <ReactLoading
                type="spokes"
                color="#ffa31a"
                height={50}
                width={50}
              />
            </div>
          </div>
          <Header />
          <CustomInput onhandleSubmit={onhandleSubmit} />
          <Row>
            <Col lg="6">
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  opacity: 0.5,
                }}
              >
                <CustomMap position={data} zoom={zoom} />
              </div>
            </Col>
            <Col lg="6">
              <InfoArea data={data} />
            </Col>
          </Row>

          <Container className="text-center  bg-dark border rounded">
            <h1 className="text-light p-2" style={{ fontSize: 30 }}>
              For Developers
            </h1>
          </Container>
          <InfoComponent url={url} data={data} />
          <Footer />
        </Container>
      ) : (
        <Container className="border border-secondary bg-light rounded mt-3 mb-2">
          <Header />
          <CustomInput onhandleSubmit={onhandleSubmit} />
          <Row>
            <Col lg="6">
              <CustomMap
                iconVisible={iconVisible}
                position={data}
                zoom={zoom}
              />
            </Col>
            <Col lg="6">
              <InfoArea data={data} />
            </Col>
          </Row>
          <Container className="text-center mt-4 bg-dark">
            <h1 className="text-light p-2 mt-2" style={{ fontSize: 30 }}>
              For Developers
            </h1>
          </Container>
          <InfoComponent url={url} data={data} />
          <Footer />
        </Container>
      )}
    </>
  );
};

export default Main;
