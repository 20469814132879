import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <Container
      className="bg-dark mt-5 mb-4 p-4 border-top border-secondary rounded-top"
      fluid
    >
      <Row>
        <Col>
          <span
            className="text-light"
            style={{ fontFamily: "Piazzolla", fontSize: 20 }}
          >
            &copy; Fizmasoft 2020
          </span>
        </Col>
      </Row>
      <span className="text-light">Version:1.0</span>
    </Container>
  );
};

export default Footer;
