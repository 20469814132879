import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const InfoArea = ({ data }) => {
  return (
    <Container
      style={{ height: 410, paddingBottom: "2em" }}
      className="mt-5  bg-dark "
    >
      {data && data.hasOwnProperty("status") ? (
        <Container className="p-4">
          <Row>
            <Col>
              <p className="text-light">Country:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.country !== "" ? data.country : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Country Code:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.countryCode !== "" ? data.countryCode : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Region:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.region !== "" ? data.region : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Region Name:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.regionName !== "" ? data.regionName : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">City:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.city !== "" ? data.city : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Zip:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.zip !== "" ? data.zip : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Lat:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.lat !== "" ? data.lat : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Lon:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.lon !== "" ? data.lon : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light">Timezone:</p>
            </Col>
            <Col>
              <p className="text-warning">
                {data.timezone !== "" ? data.timezone : "Мавжуд емас"}
              </p>
            </Col>
          </Row>
        </Container>
      ) : null}
    </Container>
  );
};

export default InfoArea;
