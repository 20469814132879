// React
import React from "react";

// Third Part
import { Container } from "react-bootstrap";
import { Map, TileLayer, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useRef, useEffect } from "react";

const CustomMap = ({ iconVisible, position, zoom }) => {
  const mapRef = useRef();

  useEffect(() => {
    if (position) {
      mapRef.current.leafletElement.setView(position, 12);
    }
  }, [position]);

  return (
    <Container className="mt-5" style={{ height: 500, width: "100%" }}>
      <Map
        viewreset
        center={position ? position : [2.4870300292968754, 5.899014874361373]}
        zoom={zoom}
        style={{ width: "100%", height: "100%" }}
        dragging={true}
        doubleClickZoom={false}
        ref={mapRef}
        onViewportChange={(val) => console.log(val)}
      >
        <TileLayer
          attribution="&amp;copy Fizmasoft"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {iconVisible ? <CircleMarker center={position} radius="64" /> : null}
      </Map>
    </Container>
  );
};

export default CustomMap;
