import React from "react";
import { Row, Container } from "react-bootstrap";
import { baseUrl } from "../service/api";
import JSONInput from "react-json-editor-ajrm";
const InfoComponent = ({ url, data }) => {
  const styles = {
    fontSize: "15px",
  };
  return (
    <Container className="bg-dark border mt-4 text-light rounded">
      {data && data.hasOwnProperty("status") ? (
        <>
          <Container className="m-2">
            <Row>
              <>
                <span>GET:</span>
                <span className="ml-3">
                  {baseUrl}
                  {url}
                </span>
              </>
              <span className=" ml-2 text-warning float-right">Free</span>
            </Row>
          </Container>
          <div className="form-group shadow-textarea">
            <JSONInput
              placeholder={data}
              height="600px"
              width="100%"
              style={{
                body: styles,
              }}
            />
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default InfoComponent;
