import React from "react";
import { Container } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
const Header = () => {
  return (
    <Container
      fluid
      className="mt-4 mb-5 bg-dark  p-2 rounded border-bottom border-secondary"
    >
      <div className="d-flex justify-content-center">
        <div className="bg-light p-1 border rounded ">
          <img src={logo} alt="Fizmasoft" />
        </div>
        <h1
          className="ml-2 mt-1 text-light "
          style={{ fontSize: "25px", fontFamily: "Piazzolla" }}
        >
          Fizmasoft Ip geo map
        </h1>
      </div>
    </Container>
  );
};

export default Header;
