import axios from "axios";
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
export const baseUrl = "https://api.fizmasoft.uz/ipgeo/";

export const getData = (url, setdata, setLoading, setIconVisible) => {
  let send = `${baseUrl}${url}`;
  if (send !== "https://api.fizmasoft.uz/ipgeo/") {
    axios.get(`${baseUrl}${url}`).then((res) => {
      if (res.data.status === "success") {
        setLoading(false);
        setdata(res.data);
        setIconVisible(true);
      } else if (res.data === "" || res.data === 400) {
        toast.error("Маьлумот топилмади", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      } else if (res.data === 500) {
        toast.error("Серверда хатолик", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      } else if (res.data === 404) {
        toast.error("Серверда хатолик", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    });
  } else {
    toast.error("Ип киритилмаган");
    setLoading(false);
  }
};
