// React
import React from "react";
import { Formik } from "formik";
// Third Part
import { Button } from "react-bootstrap";

const CustomInput = ({ onhandleSubmit }) => {
  return (
    <Formik
      initialValues={{ address: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.address) {
          errors.address = "Address ni kiritilgan!";
        } else if (
          !/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])[.]([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/.test(
            values.address
          )
        ) {
          errors.address = "Address Notog'ri kiritilgan !";
        }
        return errors;
      }}
      onSubmit={(values) => onhandleSubmit(values)}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <div className="d-flex justify-content-center">
          <div className="w-75">
            <form onSubmit={handleSubmit}>
              <div className="d-flex">
                <input
                  style={{ outline: "transparent" }}
                  placeholder="Ip адресни киритинг"
                  className="w-100 border rounded"
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                />

                <Button className="ml-2" variant="primary" type="submit">
                  Қидириш
                </Button>
              </div>
              <span className="text-danger">
                {errors.address && touched.address && errors.address}
              </span>
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CustomInput;
